import React, { useState } from "react";
import { graphql, useStaticQuery } from "gatsby";

import SearchBar from "@coninsa-s2/search-bar";

const Search = () => {
  const data = useStaticQuery(graphql`
    query MyQuery {
      allContentYaml {
        nodes {
          id
          url
          title
        }
      }
    }
  `);

  const [searchTerm, setSearchTerm] = useState("");

  const filteredPages = data.allContentYaml.nodes.filter(({ title }) =>
    title.toLowerCase().includes(searchTerm.toLowerCase())
  );

  return (
    <div>
      <SearchBar
        text="Buscar artículo de preguntas frecuentes..."
        onChange={(e) => setSearchTerm(e.target.value)}
        value={searchTerm}
      />
      {searchTerm.length > 0 && (
        <ul className="flex flex-col gap-3 rounded-b-md bg-white p-5 shadow-md">
          {filteredPages.map(({ id, title, url }) => (
            <a href={url} className="hover:rounded hover:bg-s2-gray-200" target="_blank">
              <li key={id} className="p-3 font-medium text-s2-gray-600">
                {title}
              </li>
            </a>
          ))}
        </ul>
      )}
    </div>
  );
};

export default Search;
