import React from "react";
import HeroBanner from "@coninsa-s2/banner";
import Container from "@coninsa-s2/container";
import Heading from "@coninsa-s2/heading";

import SearchBarB from "../search-bar-b";

import bannerImage from "../../../images/property-banner.jpg";
import bannerMobileImage from "../../../images/property-banner-mobile.jpg";
import Hotjar from "@coninsa-site/home/src/hotjar";

function SearchTabs() {
  const [randmonIndex, setRandmonIndex] = React.useState();

  React.useEffect(() => {
    setRandmonIndex(Math.floor(Math.random() * 2));
  }, []);

  return (
    <HeroBanner imageUrl={bannerImage} imageUrlMobile={bannerMobileImage}>
      <Container>
        <Heading tint="white" size="4xl" className="s2-banner__label">
          ¡Descubre tu inmueble ideal <br /> de vivienda o comercio!
        </Heading>
        <Hotjar trackingId={randmonIndex === 0 ? "5172709" : "5172711"} />
        <SearchBarB />
      </Container>
    </HeroBanner>
  );
}

export default SearchTabs;
