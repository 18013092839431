import React from "react";

import Section from "@coninsa-s2/section";
import Container from "@coninsa-s2/container";
import Breadcrumb, { BreadcrumbItem } from "@coninsa-s2/breadcrumb";
import Heading from "@coninsa-s2/heading";
import TableList from "@coninsa-s2/table-list";

import SearchBar from "../../../../src/search-bar/search";

export function ProsePage({
  headline,
  headlineIcon,
  title,
  body,
  breadcrumbs,
  list,
  icon,
}) {
  return (
    <>
      <Section tint="ligther-gray">
        <Container>
          <div className="mb-8">
            <Breadcrumb>
              {breadcrumbs.map((item) => (
                <BreadcrumbItem>
                  {item.url && <a href={item.url}>{item.label}</a>}
                  {!item.url && item.label}
                </BreadcrumbItem>
              ))}
            </Breadcrumb>
          </div>

          <SearchBar />
        </Container>
      </Section>

      <Section>
        <Container>
          <div className="grid-cols-12 gap-10 lg:grid">
            <div className="col-span-8 mb-10 lg:mb-0">
              <div className="mb-4 flex items-center gap-4 lg:mb-6">
                {icon && (
                  <img
                    src={icon}
                    alt="building icon"
                    className="h-20 w-20 lg:h-28 lg:w-28"
                  />
                )}

                <div>
                  <div className="flex items-center gap-4 text-lg text-s2-dark-blue lg:text-[22px]">
                    {headlineIcon && (
                      <img
                        src={headlineIcon}
                        alt="building icon"
                        className="h-12 w-12 lg:h-16 lg:w-16"
                      />
                    )}
                    {headline}
                  </div>

                  <Heading size="2xl" weight="bold">
                    {title}
                  </Heading>
                </div>
              </div>

              <div
                className="prose"
                dangerouslySetInnerHTML={{ __html: body }}
              />

              {list && (
                <TableList className="col-span-8">
                  {list.map((item) => (
                    <li>
                      <a href={item.url} target="_blank">
                        {item.label}
                      </a>
                    </li>
                  ))}
                </TableList>
              )}
            </div>

            {!list && (
              <TableList className="col-span-4">
                <h5 className="mb-4 text-base font-bold text-s2-gray-600 lg:text-lg">
                  Relacionados
                </h5>
                <li>
                  <a
                    target="_blank"
                    href="/servicio-cliente/preguntas-frecuentes/eres-comprador-de-vivienda-nueva/conoce-todo-sobre-tramites-y-creditos-de-proyectos-nuevos/que-se-requiere-para-dar-inicio-al-proceso-de-escrituracion"
                  >
                    ¿Qué se requiere para dar inicio al proceso de
                    escrituración?
                  </a>
                </li>
                <li>
                  <a
                    target="_blank"
                    href="/servicio-cliente/preguntas-frecuentes/eres-comprador-de-vivienda-nueva/conoce-todo-sobre-tramites-y-creditos-de-proyectos-nuevos/en-que-momento-recibo-copia-de-la-escritura"
                  >
                    ¿Después de firmar, en qué momento recibo copia de la
                    escritura?
                  </a>
                </li>
                <li>
                  <a
                    target="_blank"
                    href="/servicio-cliente/preguntas-frecuentes/eres-comprador-de-vivienda-nueva/conoce-todo-sobre-tramites-y-creditos-de-proyectos-nuevos/quien-se-encarga-del-proceso-de-legalizacion-del-credito"
                  >
                    ¿Quién se encarga del proceso de legalización del crédito?
                  </a>
                </li>
              </TableList>
            )}
          </div>
        </Container>
      </Section>
    </>
  );
}
