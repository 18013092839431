import phoneIcon from "./icons/phone.svg";

export default {
  contactPhones: {
    title: "Teléfonos de contacto",
    phones: [
      {
        icon: phoneIcon,
        location: "Medellín",
        phone: "(604) 448 88 28",
      },
      {
        icon: phoneIcon,
        location: "Bogotá",
        phone: "(601) 601 48 00",
      },
      {
        icon: phoneIcon,
        location: "Barranquilla",
        phone: "(605) 319 96 10",
      },
    ],
  },

  headquartersSchedules: {
    title: "Horario de nuestras sedes",
    headquarters: [
      {
        type: "Administrativas",
        schedules: [
          "<strong>Lunes a viernes</strong> de 8:00 a.m. a 5:00 p.m.",
          "<strong>Sábados</strong> de 8:00 a.m. a 12:30 p.m",
        ],
      },
      {
        type: "Arrendamientos",
        schedules: [
          "<strong>Lunes a viernes</strong> de 8:00 a.m. a 5:00 p.m.",
          "<strong>Sábados</strong> de 8:00 a.m. a 12:30 p.m",
        ],
      },
    ],
  },

  formats: {
    title: "Formatos e instructivos",
    links: [
      {
        title: "Portal de Proveedores y Contratistas",
        url: "https://proveedores.coninsa.co/index?_ga=2.151597817.1119342196.1603289995-2139408181.1596124285",
      },
      {
        title: "Certificados Tributarios Clientes Arrendamiento",
        url: "https://miconinsavirtual.coninsa.co/certificados/",
      },
      {
        title: "Certificados Tributarios Proveedores y Contratistas",
        url: "https://miconinsavirtual.coninsa.co/certificados/terceros/",
      },
      {
        title: "Guía para Compra de Inmuebles Nuevos",
        url: "https://www.coninsa.co/como-comprar-inmuebles-nuevos",
      },
      {
        title: "Guía de compras desde el exterior",
        url: "https://www.coninsa.co/desde-el-exterior",
      },
      {
        title: "Guía para usuarios de Mi Coninsa Virtual",
        url: "https://www.coninsa.co/guia-para-usuarios-de-mi-coninsa-virtual",
      },
      {
        title: "Respuestas a Preguntas Frecuentes",
        url: "https://www.coninsa.co/preguntas-frecuentes",
      },
    ],
  },
  legals: {
    title: "Legales",
    links: [
      {
        title: "Términos y Condiciones Concursos y Promociones",
        url: "https://www.coninsa.co/terminos-y-condiciones",
      },
      {
        title: "Política de Tratamiento de Datos Personales",
        url: "https://www.coninsa.co/politica-de-tratamiento-de-datos-personales-de-coninsa-ramon-h-sa",
      },
      {
        title: "Política de Datos de Navegación “Cookies”",
        url: "https://www.coninsa.co/politica-de-cookies",
      },
    ],
  },
};
